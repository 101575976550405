<template>
  <div class="flex flex-col items-center justify-center my-4">
    <p class="text-3xl font-bold text-violet-950">Movies</p>
  </div>
  <div class="grid justify-center grid-cols-5 gap-4 mb-20">
    <div v-for="poster in posters" :key="poster.title" class="m-4">
      <div class="flex flex-col items-center">
        <img
          :src="require(`@/assets/${poster.image}`)"
          :alt="poster.title"
          class="object-cover w-64 h-96"
        />
        <p class="mt-2 text-xl font-bold text-center">{{ poster.title }}</p>
        <p class="mt-2 text-sm text-center">{{ poster.year }}</p>
      </div>
    </div>
  </div>
  <div class="fixed bottom-0 left-0 right-0 p-4 bg-white shadow-md">
    <button
      v-if="!chatVisible"
      @click="toggleChat"
      class="w-full p-2 mb-2 text-white rounded-lg bg-violet-950 hover:bg-violet-800"
    >
      Ask
    </button>
    <div v-if="chatVisible">
      <div class="flex flex-row items-center justify-between gap-2">
        <input
          v-model="userQuestion"
          @keyup.enter="askQuestion"
          type="text"
          placeholder="Ask about a movie..."
          class="w-full p-2 border border-gray-300 rounded-lg"
        />
        <button
          @click="toggleChat"
          class="px-2.5 py-1 text-sm text-center text-white bg-rose-600 rounded-full hover:bg-rose-700"
        >
          x
        </button>
      </div>
      <button
        @click="askQuestion"
        class="w-full p-2 mt-2 text-white rounded-lg bg-violet-950 hover:bg-violet-800"
      >
        Ask
      </button>
      <div v-if="loading" class="p-2 mt-4 bg-gray-100 rounded-lg">
        <p class="text-sm">Loading...</p>
      </div>
      <div v-if="response && !loading" class="p-2 mt-4 bg-gray-100 rounded-lg">
        <p class="text-sm">{{ response }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from "vue";
import axios from "axios";

const posters = ref([
  { title: "Fantastic Mr. Fox", image: "fantastic-mr-fox.jpg", year: 2009 },
  { title: "Superbad", image: "superbad.jpg", year: 2007 },
  { title: "Scarface", image: "scarface.jpg", year: 1983 },
  { title: "Mulholland Drive", image: "mulholland-drive.webp", year: 2001 },
  {
    title: "Being John Malkovich",
    image: "being-john-malkovich.webp",
    year: 1999,
  },
  {
    title: "2001: A Space Odyssey",
    image: "space-odyssey-2001.jpg",
    year: 1968,
  },
  {
    title: "The Talented Mr. Ripley",
    image: "the-talented-mr-ripley.jpg",
    year: 1999,
  },
  { title: "Twilight", image: "twilight.jpg", year: 2008 },
  { title: "Titanic", image: "titanic.jpg", year: 1997 },
  {
    title: "Beauty and the Beast",
    image: "beauty-and-the-beast.jpg",
    year: 2017,
  },
]);

const userQuestion = ref("");
const response = ref("");
const loading = ref(false);
const chatVisible = ref(false);

const toggleChat = () => {
  chatVisible.value = !chatVisible.value;
};

const askQuestion = async () => {
  if (userQuestion.value.trim() === "") return;

  loading.value = true;
  response.value = "";

  try {
    const res = await axios.post("https://api-movies.campanaherrera.com/", {
      input: userQuestion.value,
    });
    response.value = res.data.content;
  } catch (error) {
    response.value = "An error occurred while fetching the answer.";
  } finally {
    loading.value = false;
  }
};
</script>
